import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Accordion from "components/Accordion";
import Section from "components/Section";

import AccordionListProps from "./AccordionListProps";

const AccordionList = ({
    accordionItems,
    contentTheme,
    actionTheme,
    theme,
    identifier,
    anchorName,
}: AccordionListProps): ReactElement => {
    return (
        <ThemeProvider
            theme={{
                theme: theme,
                contentTheme: contentTheme,
                actionTheme: actionTheme || contentTheme,
            }}
        >
            <Section
                alignItems="center"
                theme={theme}
                key={identifier}
                id={anchorName}
                editPropertyName="AccordionItems"
            >
                {accordionItems?.map((accordionItem, index) => {
                    return (
                        <Accordion
                            key={`Accordion${index}`}
                            {...accordionItem}
                            id={`${anchorName || identifier}-${index}`}
                            theme={theme}
                        />
                    );
                })}
            </Section>
        </ThemeProvider>
    );
};

export default React.memo(AccordionList);
