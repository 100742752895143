import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import AccordionProps from "./AccordionProps";

const Accordion = loadable(
    (): Promise<DefaultComponent<AccordionProps>> =>
        import(/* webpackChunkName: "Accordion" */ "./Accordion"),
);
const AccordionLoader = (props: AccordionProps): ReactElement => (
    <Accordion {...props} />
);
export default AccordionLoader;
